<template>
	<el-dialog :title="!dataForm.inspectionId ? '新增巡视信息' : '修改巡视信息'" :close-on-click-modal="false" :visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="标的物" prop="subjectNo">
						<el-select class="selItemInput" v-model="dataForm.subjectNo" placeholder="请选择">
							<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
								:label="item.displayName" :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="专业" prop="major">
						<el-select class="selItemInput" v-model="dataForm.major" placeholder="请选择">
							<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="巡视时间" prop="inspectionTime">
						<el-date-picker class="selItemInput" v-model="dataForm.inspectionTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="巡视地点" prop="inspectionPlace">
						<el-input class="selItemInput" v-model="dataForm.inspectionPlace" placeholder="请输入巡视地点"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="巡视人" prop="inspectionMan">
						<el-input class="selItemInput" v-model="dataForm.inspectionMan" placeholder="请输入巡视人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="巡视内容" prop="inspectionContent">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.inspectionContent" placeholder="请输入巡视内容"></el-input>
					</el-form-item>
				</el-col>
				<div>
					<el-col :span="12">
						<el-form-item>
							<el-checkbox v-model="probCheck" true-label="1" style="margin-left: 50px;"
								false-label="0">问题描述及整改意见(将列入问题清单中)</el-checkbox>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="重要程度" prop="iptFlg">
							<el-select class="selItemInput" v-model="dataForm.iptFlg" placeholder="请选择"
								:disabled="probCheck === '0'">
								<el-option v-for="item in importantList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label=" ">
							<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.sutContent"
								placeholder="请输入问题描述及整改意见" :disabled="probCheck === '0'"></el-input>
						</el-form-item>
					</el-col>
				</div>
				<el-col :span="24">
					<el-form-item label="现场照片">
						<el-upload ref="uploadImg" class="upload-demo" action="action" drag :http-request="uploadImg"
							multiple :show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :span="22" style="margin-left: 120px;">
					<div class="addImgBox" v-for="(item, index) in dataForm.photo" style="margin-top: 10px;">
						<el-image style="width: 100%; height: 100%" :src="item.photoPathUri" fit="cover"></el-image>
						<div class="delBox">
							<i class="el-icon-delete" @click="delImg(index)"></i>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
					@click="visible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "patrolList-add-or-update",
	data() {
		return {
			title: '新增巡视信息',
			addImg: addImg,
			id: "",
			visible: true,
			action: "",
			importantList: [],
			form: {},
			majorList: [],
			probCheck: '0',
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: "",
				inspectionId: "",
				category: '',
				major: "",
				inspectionTime: "",
				inspectionPlace: "",
				inspectionMan: '',
				inspectionContent: '',
				photo: [],
				sutContent: '',
				iptFlg: '',
				closeState: '',
			},
			dataRule: {
				subjectNo: [
					{ required: true, message: "请选择标的物", trigger: "change" }
				],
				major: [
					{ required: true, message: "请选择专业", trigger: "change" }
				],
				inspectionTime: [
					{ required: true, message: "请选择巡视时间", trigger: "blur" }
				],
				inspectionMan: [
					{ required: true, message: "请输入巡视人", trigger: "blur" }
				],
				// inspectionContent: [
				// 	{ required: true, message: "请输入巡视内容", trigger: "blur" }
				// ],
			}
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		init(id, category) {
			this.probCheck = '0'
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: "",
				inspectionId: "",
				category: '',
				major: "",
				inspectionTime: "",
				inspectionPlace: "",
				inspectionMan: '',
				inspectionContent: '',
				photo: [],
				sutContent: '',
				iptFlg: '',
				closeState: '',
			};
			this.dataForm.inspectionId = id ? id : '';
			this.dataForm.category = category;
			this.dataForm.inspectionMan = $common.getItem("userName");
			this.dataForm.inspectionTime = this.getCurrentTime();
			this.getMajorList()
			this.getProblemImportant();
			this.title = '新增巡视信息';
			if (id) {
				this.getDetail();
				this.title = '修改巡视信息';
			} else {
				this.getLastInspectionInfo();
			}
			this.$nextTick(() => {
				this.visible = true;
			});
			this.$refs["dataForm"].resetFields();
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/info/" + this.dataForm.inspectionId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.probCheck = data.body.closeState == '2' ? '0' : '1';
					this.dataForm.inspectionId = data.body.inspectionId
					this.dataForm.workNo = data.body.workNo
					this.dataForm.subjectNo = data.body.subjectNo
					this.dataForm.category = data.body.category
					this.dataForm.major = data.body.major
					this.dataForm.inspectionTime = data.body.inspectionTime
					this.dataForm.inspectionPlace = data.body.inspectionPlace
					this.dataForm.inspectionMan = data.body.inspectionMan
					if (!data.body.inspectionMan) {
						this.dataForm.inspectionMan = $common.getItem("userName");
					}
					this.dataForm.inspectionContent = data.body.inspectionContent
					this.dataForm.photo = data.body.photo
					this.dataForm.closeState = data.body.closeState
					this.dataForm.sutContent = data.body.problem.sutContent
					this.dataForm.iptFlg = data.body.problem.iptFlg
				}
			});
		},
		getLastInspectionInfo() {
			let _url = "/business/inspectioninfo/getLastInspectionInfo";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: this.dataForm.workNo,
					category: this.dataForm.category,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.subjectNo = data.body.subjectNo
					this.dataForm.major = data.body.major
				}
			});
		},
		getProblemImportant() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getProblemImportant",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.importantList = data.body;
				}
			});
		},
		//图片
		delImg(index) {
			let _list = this.dataForm.photo;
			_list.splice(index, 1);
			this.dataForm.photo = _list;
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
				formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.photo.push({
						photoName: res.body.fileName,
						photoPath: res.body.fileSavePath,
						photoPathUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		dataFormSubmit() {
			if (this.probCheck === '0') {
				this.dataForm.sutContent = ''
			}
			if (this.probCheck === '1' && !this.dataForm.sutContent) {
				this.$message.error("请输入问题描述及整改意见");
				return;
			}
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/inspectioninfo/save";
					if (this.dataForm.inspectionId) {
						_url = "/business/inspectioninfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.el-form-item {
	margin-bottom: 20px !important;
}

.selItemInput.descAreaLg {
	width: 740px !important;
}

.selItemInput.descAreaLg1 {
	width: 918px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
</style>
