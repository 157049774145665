<template>
	<el-dialog :title="title" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="155px" class="border-form" :inline="true"
			style="margin-right: 25px;">
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="标的物" class="item">
						<span class="set_data"> {{ dataForm.subjectName }} </span>
					</el-form-item>
				</el-col>
				<!-- <el-col :span="8" class="set_border">
					<el-form-item label="范畴" class="item">
						{{ dataForm.categoryName }}
					</el-form-item>
				</el-col> -->
				<el-col :span="12">
					<el-form-item label="专业" class="item">
						<span class="set_data">{{ dataForm.majorName }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="提出人" class="item">
						<span class="set_data">{{ dataForm.inspectionMan }}</span>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="提出时间" class="item">
						<span class="set_data">{{ dataForm.inspectionTime | formatDate('yyyy-MM-dd') }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="巡检内容" class="item">
						<span class="set_data">{{ dataForm.inspectionContent }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="set_border">
					<el-form-item label="问题描述及整改意见" class="item" label-widt="200px">
						<span class="set_data">{{ dataForm.sutContent }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="封闭人" class="item">
						<span class="set_data">{{ dataForm.closeMan }}</span>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="封闭时间" class="item">
						<span class="set_data">{{ dataForm.closeTime | formatDate('yyyy-MM-dd') }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="备注" class="item">
						<span class="set_data">{{ dataForm.remark }}</span>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" class="row-form-item">
					<el-form-item label="整改前照片" class="fileItem">
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-left: 30px;margin-top: 10px;">
					<div class="addImgBox" v-for="(item, index) in dataForm.scenePhotoList"
						style="margin-top: 5px;margin-bottom: 10px;">
						<el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
					</div>
					<div v-if="!dataForm.scenePhotoList || dataForm.scenePhotoList.length === 0"
						style="margin-top: 10px;margin-bottom: 10px;margin-left: 90px;">
						<span style="color: #e7b243;">暂无整改前照片</span>
					</div>
				</el-col>
				<el-col :span="24" class="row-form-item">
					<el-form-item label="整改后照片" class="fileItem">
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-left: 30px;margin-top: 10px;">
					<div class="addImgBox" v-for="(item, index) in dataForm.closePhotoList"
						style="margin-top: 5px;margin-bottom: 10px;">
						<el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
					</div>
					<div v-if="!dataForm.closePhotoList || dataForm.closePhotoList.length === 0"
						style="margin-top: 10px;margin-bottom: 10px;margin-left: 90px;">
						<span style="color: #e7b243;">暂无整改后照片</span>
					</div>
				</el-col>
				<el-col :span="24" class="row-form-item">
					<el-form-item label="封闭文件" class="fileItem">
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-left: 30px;margin-bottom: 10px;margin-top: 10px;" class="row-form-item">
					<el-table :data="dataForm.closeFileList" :cell-style="{ padding: '0px' }" style="margin-bottom: 20px;"
						:show-header="false" v-if="dataForm.closeFileList && dataForm.closeFileList.length > 0">
						<el-table-column width="100" align="center" type="index">
						</el-table-column>
						<el-table-column prop="fileName" width="600" align="center">
							<template slot-scope="scope">
								<el-button v-preventReClick type="text" size="small"
									@click="viewPdf(scope.row.filePath, scope.row.fileUri, scope.row.fileName)">{{
										scope.row.fileName }}</el-button>
							</template>
						</el-table-column>
						<el-table-column width="165" align="center">
							<template slot-scope="scope">
								<el-button v-preventReClick type="text" size="small"
									@click="downloadFile(scope.row.fileUri, scope.row.fileName)">下载</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div v-if="!dataForm.closeFileList || dataForm.closeFileList.length === 0"
						style="margin-top: 10px;margin-bottom: 10px;margin-left: 90px;">
						<span style="color: #e7b243;">暂无封闭文件</span>
					</div>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	name: "patrolList-Info",
	data() {
		return {
			viewPdfVisible: false,
			title: '详情',
			addImg: addImg,
			categoryList: [],
			sutConclusionList: [],
			id: "",
			visible: true,
			action: "",
			form: {},
			probCheck: '0',
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: "",
				inspectionId: "",
				category: '',
				major: "",
				inspectionTime: "",
				inspectionPlace: "",
				inspectionMan: '',
				inspectionContent: '',
				photo: [],
				sutContent: '',
				problem: {}
			},
			dataRule: {
			}
		};
	},
	components: {
		ViewPdf
	},
	activated() {

	},
	filters: {
		formatDate: function (value, args) {
			if (!value) {
				return ''
			}
			var dt = new Date(value);
			if (args == 'yyyy-M-d') {// yyyy-M-d
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				return `${year}-${month}-${date}`;
			} else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				let hour = dt.getHours();
				let minute = dt.getMinutes();
				let second = dt.getSeconds();
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			} else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${date}`;
			} else {// yyyy-MM-dd HH:mm:ss
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				let hour = dt.getHours().toString().padStart(2, '0');
				let minute = dt.getMinutes().toString().padStart(2, '0');
				let second = dt.getSeconds().toString().padStart(2, '0');
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			}
		}
	},
	methods: {

		getSutConclusionName(value) {
			let name = '';
			if (!this.sutConclusionList) {
				this.$http({
					url: this.$store.state.httpUrl + "/common/getSutConclusion",
					method: "get",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.sutConclusionList = data.body;
						this.sutConclusionList.forEach(function (item) {
							if (item.displayValue === value) {
								name = item.displayName;
							}
						});
					}
				});
			} else {
				this.sutConclusionList.forEach(function (item) {
					if (item.displayValue === value) {
						name = item.displayName;
					}
				});
			}
			return name;
		},
		init(id) {
			this.getCategoryList();
			this.dataForm.inspectionId = id ? id : '';
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			});
			if (this.$refs) {
				this.$refs["dataForm"].resetFields();
			}
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/info/" + this.dataForm.inspectionId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (!this.dataForm.inspectionMan) {
						this.dataForm.inspectionMan = $common.getItem("userName");
					}
				}
			});
		},
		getCategoryList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getCategoryList",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.categoryList = data.body;
				}
			});
		},
		getCategoryName(categoryId) {
			this.categoryList.forEach((item) => {
				if (categoryId === item.displayValue) {
					return item.displayName
				}
			})
		},
	}
};
</script>

<style lang="scss" scoped>
.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.fileItem::v-deep .el-form-item__label {
	text-align: left;
	padding-left: 20px;
	color: #00428e !important;
	font-weight: bold !important;
	;
	width: 100% !important;
	background-color: #E1F1FF;
}


.row-form-item {
	margin-top: 10px;
}

.row-form-item .el-form-item {
	margin-right: 0;
}


.border-form .el-row.set_border {
	border-bottom: 0.5px solid #dadada;
	border-left: 0.5px solid #dadada;
	border-right: 0.5px solid #dadada;
}

.border-form .el-row.set_border:first-child {
	border-top: 0.5px solid #dadada;
}

.border-form .el-row.set_border .col-set_border {
	border-right: 0.5px solid #dadada;
}

.selItemInput.descAreaLg {
	width: 718px !important;
}

.selItemInput.descAreaLg1 {
	width: 918px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.set_data {
	color: #333333;
	background-color: #FFFFFF;
}
</style>
