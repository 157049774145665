<template>
	<!-- 巡视信息列表 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">{{ title }}</div>
			<div class="line"></div>
			<el-row style="margin-top: -10px;margin-left: -20px;width: 100%; line-height:30px;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue" @click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue" @click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<el-row style="margin-top: 17px;">
					<el-col :span="2">
						<div class="search_title">专业</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(maj, index) in majorList" style="margin-right: 50px;">
							<span class="search_bn_border" v-if="major === maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
							<span class="search_bn" v-else-if="major !== maj.displayValue" @click="setMajor(maj)">
								{{ maj.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="11" style="margin-left:15px;margin-top:5px">
						<!-- <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline"
					@click="addOrUpdateHandle()" v-if="showAdd">添加前</el-button> -->
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="addHandle()"
							v-if="showAdd" :disabled="updateFlg || addFlg">新增</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="uploadHandle()">导入</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadHandle()">导出</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
							@click="downloadTemplate()">模板下载</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							:disabled="disabledBatchDelete || updateFlg || addFlg" @click="deleteBatch()">批量删除</el-button>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							:disabled="updateFlg || addFlg" @click="downloadAllDataHandle()">所有数据导出</el-button>
					</el-col>
					<el-col :span="12" style="margin-top: 15px;">
						<!-- <div style="float: right;margin-right: 15px;vertical-align:middle;">
						<span style="font-size: 16px; font-weight:bold;vertical-align: middle">已封闭 {{ dataTotal.closeNum }} 未封闭 {{ dataTotal.nonCloseNum }}</span>
					</div> -->
						<div style="float: right;margin-right: 15px;vertical-align:middle;margin-top: -5px;">
							<span style="font-size: 16px; font-weight: bold;vertical-align: middle">已封闭 </span>
							<span
								style="font-size: 16px; font-weight: bold;vertical-align: middle;color:rgb(67, 167, 92);">{{
									dataTotal.closeNum }} </span>
							<span style="font-size: 16px; font-weight: bold;vertical-align: middle">未封闭 </span>
							<span
								style="font-size: 16px; font-weight:bold;vertical-align: middle;color:rgb(207, 67, 91);">{{
									dataTotal.nonCloseNum }}</span>
						</div>
					</el-col>
				</el-row>
			</el-row>
			<div class="line" style="margin-top: -4px;"></div>
			<el-form ref="form" :model="form" inline label-width="70px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="20" style="margin-left:20px">
						<!-- <el-form-item label="标的物" class="selFormItem">
							<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择"
								:disabled="updateFlg || addFlg" clearable>
								<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue"
									:label="item.displayName" :value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="专业" class="selFormItem">
							<el-select class="selMajorInput" v-model="major" placeholder="请选择"
								:disabled="updateFlg || addFlg" clearable>
								<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label="状态" class="selFormItem">
							<el-select class="selMajorInput" v-model="closeState" placeholder="请选择"
								:disabled="updateFlg || addFlg" clearable>
								<el-option v-for="item in closeStateList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="问题描述及整改意见" class="selFormItem2">
							<el-input class="selSearchInput" v-model="sutContent" :disabled="updateFlg || addFlg" clearable></el-input>
						</el-form-item>
						<el-form-item label="巡视时间" class="selFormItem">
							<el-date-picker class="selDateInput" v-model="poseTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
								end-placeholder="结束日期" :disabled="updateFlg || addFlg" clearable>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							:disabled="updateFlg || addFlg" v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" :disabled="updateFlg || addFlg"
							@click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -30px; height: 460px;" body-style="height:420px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="400" :row-style="selectRowBackground" :row-class-name="tableRowClassName"
								ref="dataListTable" class="dataListTable" :data="dataList" header-align="center" border
								header-cell-class-name="dataListTHeader" @selection-change="handleSelectionChange"
								:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;">
								<el-table-column type="selection" width="50" align="center"></el-table-column>
								<el-table-column label="NO." width="60" align="center" fixed="left">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" fixed="left" width="135"
									header-align="center" align="center">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.subjectName
										}}</span>
										<el-select class="sp-input" v-model="scope.row.subjectNo" placeholder="请选择"
											v-if="getDisabled(scope.row.inspectionId)" style="width:110px">
											<el-option v-for="item in $store.state.common.navBarSubjectList"
												:key="item.displayValue" :label="item.displayName"
												:value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column prop="majorName" label="专业" width="125" align="center" fixed="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.majorName
										}}</span>
										<el-select class="sp-input" v-model="scope.row.major" placeholder="请选择"
											v-if="getDisabled(scope.row.inspectionId)" style="width:100px">
											<el-option v-for="item in majorList" :key="item.displayValue"
												:label="item.displayName" :value="item.displayValue">
											</el-option>
										</el-select>
									</template>
								</el-table-column> -->
								<el-table-column prop="inspectionContent" label="巡视内容" min-width="150" header-align="center"
									show-overflow-tooltip align="left" fixed="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.inspectionContent
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.inspectionContent"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="sutContent" label="问题描述及整改意见" header-align="center" min-width="350"
									show-overflow-tooltip align="left" fixed="left">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">
											<el-button v-preventReClick type="text" size="small"
												@click="lookHandle(scope.row.inspectionId)">{{ scope.row.sutContent
												}}</el-button></span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.sutContent"></el-input>
									</template>
								</el-table-column>
								<el-table-column label="提出时间" prop="inspectionTime" width="150" align="center">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)">
											{{ scope.row.inspectionTime | formatDate('yyyy-MM-dd') }}</span>
										<el-date-picker class="sp-input" v-model="scope.row.inspectionTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
											v-if="getDisabled(scope.row.inspectionId)">
										</el-date-picker>
									</template>
								</el-table-column>
								<el-table-column label="提出人" prop="inspectionMan" width="120" align="center">
									<template slot-scope="scope">
										<span style="margin-left: 15px;" v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.inspectionMan
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.inspectionMan"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="closeTime" label="封闭时间" width="150" align="center">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)
											&& scope.row.closeTime != ''">
											{{ scope.row.closeTime | formatDate('yyyy-MM-dd') }}</span>
										<span v-if="!getDisabled(scope.row.inspectionId)
											&& scope.row.sutContent == '' && scope.row.closeTime == null">
											/</span>
										<el-date-picker class="sp-input" v-model="scope.row.closeTime" type="date"
											value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px;"
											v-if="getDisabled(scope.row.inspectionId)">
										</el-date-picker>
									</template>
								</el-table-column>
								<el-table-column prop="closeMan" label="封闭人" width="130" align="center">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.closeMan
										}}</span>
										<span v-if="!getDisabled(scope.row.inspectionId)
												&& scope.row.sutContent == '' && scope.row.closeMan == ''">
											/</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.closeMan"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="closeOpinion" label="封闭意见说明" width="250" align="center">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.closeOpinion
										}}</span>
										<span v-if="!getDisabled(scope.row.inspectionId)
												&& scope.row.sutContent == '' && scope.row.closeOpinion == ''">
											/</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.closeOpinion"></el-input>
									</template>
								</el-table-column>
								<el-table-column prop="remark" label="备注" width="250" align="center" show-overflow-tooltip>
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.inspectionId)">{{
											scope.row.remark
										}}</span>
										<el-input class="sp-input" v-if="getDisabled(scope.row.inspectionId)"
											v-model="scope.row.remark"></el-input>
									</template>
								</el-table-column>
								<!-- <el-table-column align="center" label="状态" width="100">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4" class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()" v-if="showSearch">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <el-row style="margin-top: 20px;">
	  	<el-col :span="pageSpan">
	  		<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline" @click="addOrUpdateHandle()" v-if="showAdd">添加</el-button>
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload" @click="uploadHandle()">导入</el-button>
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download" @click="downloadHandle()">导出</el-button>
			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download" @click="downloadTemplate()">模板下载</el-button>
	  	</el-col>
	  	<el-col :span="pageSpan" style="text-align: right;">
	  		<el-pagination
	  			background
	  			@current-change="currentChangeHandle"
	  			layout="prev, pager, next"
	  			:current-page="pageIndex"
	  			:page-size="pageSize"
	  			:total="totalPage">
	  		</el-pagination>
	  	</el-col>
	  </el-row>
	  <div :style="siteContentViewHeight()">
		  <el-card
				  style="margin-top: 20px; height: 100%;"
				  body-style="height: 100%; padding: 0;">
			  <el-table
					  ref="dataListTable"
					  class="dataListTable"
					  :data="dataList"
					  header-align="center"
					  height="100%"
					  header-cell-class-name="dataListTHeader">
				  <el-table-column width="30" align="center" fixed="left">
						<template slot-scope="scope"><span class="iconfont icon-xinfeng" v-if='scope.row.stateFlg === "1"'></span></template>
				  </el-table-column>
				  <el-table-column label="序号" width="60" align="center" fixed="left">
						<template slot-scope="scope">{{(pageIndex-1) * pageSize + scope.$index + 1}}</template>
				  </el-table-column>
				  <el-table-column
						  prop="subjectName"
						  label="标的物"
						  fixed="left"
						  width="200"
						  header-align="center"
						  show-overflow-tooltip
						  align="center">
				  </el-table-column>
				  <el-table-column
						  prop="majorName"
						  label="专业"
						  width="100"
						  align="center">
				  </el-table-column>
				  <el-table-column
						  prop="inspectionContent"
						  label="巡视内容"
						  width="400"
						  header-align="center"
						  show-overflow-tooltip
						  align="left">
				  </el-table-column>
				  <el-table-column
						  prop="problem.sutContent"
						  label="问题描述及整改意见"
						  header-align="center"
						  width="400"
						  show-overflow-tooltip
						  align="left">
				  </el-table-column>
				  <el-table-column
						  label="提出时间"
						  prop="inspectionTime"
						  width="100"
						  align="center">
				  </el-table-column>
				  <el-table-column
						  label="提出人"
						  prop="inspectionMan"
						  width="100"
						  align="center">
				  </el-table-column>
				  <el-table-column align="center" label="状态" width="100">
					  <template slot-scope="scope">
						  <el-tag v-if="scope.row.stateFlg == 1" effect="plain">未封闭</el-tag>
						  <el-tag v-if="scope.row.stateFlg == 3" effect="plain" type="success">已封闭</el-tag>
					  </template>
				  </el-table-column> -->
								<el-table-column label="操作" width="140" align="center" fixed="right">
									<template slot-scope="scope">
										<!-- <el-button v-preventReClick type="text" size="mini"
									@click="addOrUpdateHandle(scope.row.inspectionId)"
									v-if="showEdit && !updateFlg && !addFlg">修改前</el-button> -->
										<el-button v-preventReClick type="text" size="mini"
											@click="updateHandle(scope.row.inspectionId)"
											v-if="showEdit && !updateFlg && !addFlg">修改</el-button>
										<!-- <el-button v-preventReClick type="text" size="mini" v-if="!updateFlg && !addFlg"
									@click="deleteHandle(scope.row.inspectionId)">删除</el-button> -->
										<el-button v-preventReClick type="text" size="mini"
											@click="picAndFileHandle(scope.row.inspectionId)"
											v-if="!updateFlg && !addFlg">照片与文件</el-button>
										<!--新增时按钮-->
										<el-button v-preventReClick type="text" size="small" @click="saveInspection()"
											v-if="scope.row.inspectionId == undefined && addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelSaveHandle()"
											v-if="scope.row.inspectionId == undefined && addFlg">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="small"
											@click="updateInspection(scope.row)"
											v-if="getDisabled(scope.row.inspectionId) && !addFlg">保存</el-button>
										<el-button v-preventReClick type="text" size="small" @click="cancelUpdateHandle()"
											v-if="getDisabled(scope.row.inspectionId) && !addFlg">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper"
							:disabled="updateFlg || addFlg">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
		<patrol-list-info v-if="lookVisible" ref="patrolInfo" @refreshDataList="getDataList()"></patrol-list-info>
		<close-problem v-if="closeProblemVisible" ref="closeProblem" @refreshDataList="getDataList()"></close-problem>
		<cancel-problem v-if="cancelProblemVisible" ref="cancelProblem" @refreshDataList="getDataList()"></cancel-problem>
		<excel-upload v-if="excelUploadVisible" ref="excelUpload" @refreshDataList="getDataList"></excel-upload>
		<pic-and-file v-if="picAndFileVisible" ref="picAndFile" @refreshDataList="getDataList"></pic-and-file>
	</div>
</template>
<script>
import AddOrUpdate from './patrolList-add-or-update'
import CloseProblem from './../problemOpi/problem-close'
import CancelProblem from './../problemOpi/problem-cancel'
import $common from "@/utils/common.js"
import PatrolListInfo from "./patrolList-Info";
import ExcelUpload from './patrolList-excel-upload'
import PicAndFile from './patrolList-pic-and-file'
export default {
	data() {
		return {
			tabIndex: "0",
			showAdd: false,
			showSearch: false,
			showLook: false,
			showEdit: false,
			showDelete: false,
			lookVisible: false,
			addOrUpdateVisible: false,
			excelUploadVisible: false,
			disabledBatchDelete: true,
			picAndFileVisible: false,
			updateFlg: false,
			addFlg: false,
			closeStateList: [{
				label: '未封闭',
				value: '0'
			}, {
				label: '已封闭',
				value: '1'
			}],
			sutContent: '',
			closeProblemVisible: false,
			cancelProblemVisible: false,
			form: {},
			categoryList: [],
			category: 0,
			subjectList: [],
			majorList: [],
			dataList: [],
			tableSelVal: [],
			tableSelIdArray: [],
			selAllFlg: false,
			subjectNo: "",
			major: "",
			closeState: '',
			poseTime: [],
			closeTime: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			pageSpan: 12,
			recNos: [],
			importantList: [],
			dataTotal: {
				closeNum: 0,
				nonCloseNum: 0
			},
			title: ''
		};
	},
	components: {
		PatrolListInfo,
		AddOrUpdate, CloseProblem,
		CancelProblem,
		ExcelUpload,
		PicAndFile
	},
	filters: {
		formatDate: function (value, args) {
			if (!value) {
				return ''
			}
			var dt = new Date(value);
			if (args == 'yyyy-M-d') {// yyyy-M-d
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				return `${year}-${month}-${date}`;
			} else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				let hour = dt.getHours();
				let minute = dt.getMinutes();
				let second = dt.getSeconds();
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			} else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${date}`;
			} else {// yyyy-MM-dd HH:mm:ss
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				let hour = dt.getHours().toString().padStart(2, '0');
				let minute = dt.getMinutes().toString().padStart(2, '0');
				let second = dt.getSeconds().toString().padStart(2, '0');
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			}
		}
	},
	mounted() {
		this.category = this.$route.query.type;
		if (this.category === '1') {
			this.title = '质量巡视'
		} else if (this.category === '2') {
			this.title = '安全巡视'
		}
		this.showAdd = $common.isAuth('patrol:add:' + this.category);
		this.showSearch = $common.isAuth('patrol:search:' + this.category);
		this.showLook = $common.isAuth('patrol:look:' + this.category);
		this.showEdit = $common.isAuth('patrol:edit:' + this.category);
		this.showDelete = $common.isAuth('patrol:delete:' + this.category);
		if (!this.showAdd) {
			this.pageSpan = 24;
		} else {
			this.pageSpan = 12;
		}
		this.pageIndex = 1;
		this.pageIndex2 = 1;
		// if (this.showSearch) {
		// 	this.getDataList();
		// }
		this.getSubjectDropDown();
		this.getMajorList();
		this.getProblemImportant();
	},
	methods: {
		getCurrentTime() {
			//获取当前时间并打印
			var _this = this;
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		addHandle() {
			let _url = "/business/inspectioninfo/getLastInspectionInfo";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData({
					workNo: $common.getItem("workNo"),
					category: this.category,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 首行新增一条记录
					let dataList = [];
					let mooringTest = {
						workNo: $common.getItem("workNo"),
						subjectNo: this.subjectNo,
						major: this.major,
						category: this.category,
						inspectionMan: $common.getItem("userName"),
						inspectionPlace: '',
						inspectionContent: '',
						sutContent: '',
						inspectionTime: this.getCurrentTime(),
						closeTime: '',
						closeMan: '',
						closeOpinion: '',
						iptFlg: '',
						remark: '',
					}
					dataList.push(mooringTest);
					for (var i = 0; i < this.dataList.length; i++) {
						dataList.push(this.dataList[i])
					}
					this.dataList = dataList
					this.addFlg = true;
				}
			});
		},
		cancelSaveHandle() {
			this.addFlg = false;
			let dataList = [];
			for (var i = 0; i < this.dataList.length; i++) {
				if (this.dataList[i].inspectionId != undefined) {
					dataList.push(this.dataList[i])
				}
			}
			this.dataList = dataList
		},
		updateHandle(val) {
			this.recNos.push(val)
			this.updateFlg = true;
		},
		cancelUpdateHandle() {
			this.getDataList()
			this.recNos = [];
			this.updateFlg = false;
		},
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.recNos.includes(val) && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		saveInspection() {
			if (this.dataList[0].subjectNo == null || this.dataList[0].subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (this.dataList[0].inspectionTime == null || this.dataList[0].inspectionTime == "") {
				this.$message.error("请填写提出时间");
				return;
			}

			if (this.dataList[0].inspectionMan == null || this.dataList[0].inspectionMan == "") {
				this.$message.error("请填写提出人");
				return;
			}

			let _url = "/business/inspectioninfo/save";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataList[0])
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.addFlg = false
				}
			});
		},
		updateInspection(val) {
			if (val.subjectNo == null || val.subjectNo == "") {
				this.$message.error("请选择标的物");
				return;
			}

			if (val.inspectionTime == null || val.inspectionTime == "") {
				this.$message.error("请填写提出时间");
				return;
			}

			if (val.inspectionMan == null || val.inspectionMan == "") {
				this.$message.error("请填写提出人");
				return;
			}

			let _url = "/business/inspectioninfo/update";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.updateFlg = false
					this.recNos = [];
				}
			});
		},
		getProblemImportant() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getProblemImportant",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.importantList = data.body;
				}
			});
		},
		picAndFileHandle(id) {
			this.picAndFileVisible = true;
			this.$nextTick(() => {
				this.$refs.picAndFile.init(id)
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		checkboxInit(row) {
			return 1;
		},
		lookHandle(id) {
			this.lookVisible = true;
			this.$nextTick(() => {
				this.$refs.patrolInfo.init(id)
			})
		},
		uploadHandle() {
			this.excelUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.excelUpload.init(this.category)
			})
		},
		downloadTemplate() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getTemplateUrl?type=4",
				method: 'get',
				params: {}
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					if (data.body) {
						if (this.$store.state.showTestFlag) {
							window.open(data.body)
						} else {
							this.global.downLoadFile(data.body, '巡视记录表模板.xlsx');
						}
					} else {
						this.$message.error("未配置模板，请联系运维管理人员")
					}
				}
			});
		},
		downloadHandle() {
			const rLoading = this.openLoading();
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/download",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					category: this.category,
					major: this.major,
					closeState: this.closeState,
					sutContent : this.sutContent,
					beginTime: this.poseTime[0] ? this.poseTime[0] : "",
					endTime: this.poseTime[1] ? this.poseTime[1] : "",
				})
			}).then(({ data }) => {
				rLoading.close();
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '巡视记录表.xlsx');
					}
				} else {
				}
			})
		},
		downloadAllDataHandle() {
			const rLoading = this.openLoading();
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/download",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
				})
			}).then(({ data }) => {
				rLoading.close();
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '巡视记录表.xlsx');
					}
				} else {
				}
			})
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.category)
			})
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList()
		},
		countInspection() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/countInspection",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					category: this.category,
					major: this.major,
					closeState: this.closeState,
					sutContent: this.sutContent,
					beginTime: this.poseTime[0] ? this.poseTime[0] : "",
					endTime: this.poseTime[1] ? this.poseTime[1] : "",
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataTotal = data.body
				}
			})
		},
		getDataList() {
			this.countInspection()
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/list",
				method: "get",
				params: {
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					category: this.category,
					major: this.major,
					closeState: this.closeState,
					sutContent: this.sutContent,
					beginTime: this.poseTime[0] ? this.poseTime[0] : "",
					endTime: this.poseTime[1] ? this.poseTime[1] : "",
					limit: this.pageSize,
					page: this.pageIndex
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				}
			});
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
					if (this.majorList != undefined && this.majorList.length > 0) {
						this.major = this.majorList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setMajor(major) {
			if (!this.addFlg && !this.updateFlg) {
				this.major = major.displayValue
				this.getDataList()
			}
		},
		setSubject(subject) {
			if (!this.addFlg && !this.updateFlg) {
				this.subjectNo = subject.displayValue
				this.getDataList()
			}
		},
		toggleAllSelection() {
			this.$refs.dataListTable.toggleAllSelection();
		},
		resetFormData() {
			// this.subjectNo = "";
			// this.major = "";
			this.closeState = ""
			this.sutContent = ''
			this.poseTime = [];
		},
		closeProblemHandle(id) {
			this.closeProblemVisible = true
			this.$nextTick(() => {
				this.$refs.closeProblem.init(id)
			})
		},
		cancelProblemHandle(id) {
			this.cancelProblemVisible = true
			this.$nextTick(() => {
				this.$refs.cancelProblem.init(id)
			})
		},
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/inspectioninfo/delete/" + id,
					method: "POST"
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.inspectionId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/inspectioninfo/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			});
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.inspectionId);
			if (checkIdList.includes(row.inspectionId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped>
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.noteTitle {
	font-weight: bold;
	color: #00428e;
	border-bottom: 1px solid #00428e;
	padding-bottom: 10px;
}

.noteContent {
	color: #727580;
	margin-top: 10px;
	line-height: 25px;
}

.my-pagination {
	margin-right: 20px !important;
	margin-top: -10px !important;
}

::v-deep(.el-table th.el-table__cell) {
	/*background-color: #d3e2f4 !important;*/
	font-size: 15px;
	padding: 2px 0;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
	/* text-align: center; */
}

.selSubjectInput /deep/ .el-input__inner {
	width: 120px !important;
	height: 30px !important;
}

.selDateInput {
	width: 270px !important;
	margin-top: 5px;
	height: 32px;

	/* ::v-deep .el-input__inner {
		height: 33px;
	} */
}

.selSearchInput /deep/ .el-input__inner {
	width: 180px !important;
	height: 30px !important;
}

.selMajorInput /deep/ .el-input__inner {
	width: 110px !important;
	height: 30px !important;
}

.selDateInput /deep/ .el-range-separator {
	width: 40px !important;
	margin-top: -8px;
}

.selFormItem::v-deep .el-form-item__label {
	font-size: smaller;
}

.selFormItem2::v-deep .el-form-item__label {
	font-size: smaller;
	width: 120px !important;
	margin-left: -10px;
	margin-top: -1px
}

.box {
	height: 100%;
	overflow: auto;
}

::v-deep(.el-table .el-table__body-wrapper) {
	z-index: 2
}
</style>
